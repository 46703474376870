import { measureTime } from './uptimeMeasure'
import { analytics } from './Analytics/Analytics'

export const LOADING_PROGRESS = {
  LOAD_PROGRESS: 'Load Progress',
  LOAD_FINISHED: 'Load Finished',
}

const _reported: Record<string, boolean> = {}

export const markLoadingProgress = (loadingProgress: string) => {
  if (!_reported[loadingProgress]) {
    analytics.track(loadingProgress, {
      Path: window.location.pathname,
      Time: measureTime(loadingProgress),
    })

    _reported[loadingProgress] = true
  }
}

const REPORT_GAP_SECONDS = 10
const MAX_REPORTS = 3
export const reportLoadingProgress = () => {
  let timesReported = 0
  const interval = setInterval(() => {
    if (
      timesReported >= MAX_REPORTS ||
      _reported[LOADING_PROGRESS.LOAD_FINISHED]
    ) {
      clearInterval(interval)
      return
    }

    timesReported += 1
    markLoadingProgress(
      `${LOADING_PROGRESS.LOAD_PROGRESS} - ${
        timesReported * REPORT_GAP_SECONDS
      } Seconds`
    )
  }, REPORT_GAP_SECONDS * 1000)
}
