const _measurementLabels: Record<string, number> = {}

export const MEASUREMENT_LABELS = {
  HOMEPAGE: 'homepage',
  ONBOARDING: 'onboarding',
}

// Only measure on the first time - for example if redirected from homepage to onboarding, only count the onboarding time
export function didMeasure(): boolean {
  return Object.keys(_measurementLabels).length > 0
}

export function measureTime(label: string): number {
  if (_measurementLabels[label]) {
    return _measurementLabels[label]
  }

  _measurementLabels[label] = Date.now() - window.requestTime
  return _measurementLabels[label]
}
